import axios from "axios";
import { create } from "apisauce";
import { messageToAssistant } from "./chat-bot-new";

const api = create({
  headers: {
    "x-api-key": process.env.REACT_APP_X_API_KEY,
  },
});
const handlePromise = (resolve, reject, response) => {
  if (
    (response?.data?.success && response.status === 200) ||
    response.status === 201 ||
    response.originalError === null
  ) {
    resolve(response.data);
  } else {
    reject(response?.data);
  }
};
const API_URL = process.env.REACT_APP_API_LINK;

export const sendMessageToModuleGpt = async (payload, id) => {
  const isHandle = true;
  const Header = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const response = await api.post(
    `${API_URL}/module/${id}/chat`,
    payload,
    Header
  );
  if (isHandle) {
    return new Promise((resolve, reject) => {
      handlePromise(resolve, reject, response);
    });
  } else {
    return response;
  }
};

export const sendGenricEmail = async (data) => {
  const res = await axios.post(`${API_URL}/email/send-generic-email`, data);
  return res.data;
};

const moderateText = async (text) => {
  const response = await axios.post(
    "https://api.openai.com/v1/moderations",
    {
      model: "omni-moderation-latest",
      input: text,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
      },
    }
  );

  return response.data.results[0];
};

export const createThreadId = async () => {
  const response = await axios.post(
    "https://api.openai.com/v1/threads",
    {
      // model: 'gpt-4o',
      messages: [],
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        "OpenAI-Beta": "assistants=v2",
      },
    }
  );
  return response?.data?.id;
};

export const sendMessageToGpt = async ({
  newMessages,
  data,
  pageName,
  gradeNumber,
  askAnythingPrompt,
  threadId,
  moduleName,
  lessonName,
  skillName,
  subSkillName,
  subSkillDescription,
  selectedLanguage,
}) => {
  const latestMessage = newMessages[newMessages.length - 1];
  // const moderationResult = await moderateText(latestMessage.message);
  // if (moderationResult.flagged) {
  //   return ({ isFlagged: true, message: "Message cannot be sent as it contains inappropriate content." })
  // }
  if (threadId) {
    const response = await messageToAssistant(
      latestMessage?.message,
      threadId,
      gradeNumber,
      selectedLanguage
    );
    return response;
  }
  let payload = {
    newMessages,
    data,
    pageName,
    gradeNumber,
    askAnythingPrompt,
    moduleName,
    lessonName,
    skillName,
    subSkillName,
    subSkillDescription,
    selectedLanguage,
  };
  const response = await axios.post(
    "https://api.openai.com/v1/chat/completions",
    {
      model: "gpt-4o",
      messages: [
        { role: "system", content: constructPrompt(payload) },
        ...newMessages.map((msg) => ({
          role: msg.sender,
          content: msg.message,
        })),
      ],
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
      },
    }
  );
  return response.data.choices[0].message.content;
};

export const sendYoutubeVideoQuery = async (query, orgId) => {
  const response = await api.get(
    `https://ai-server.stickball.biz/search_youtube?query=${query}&org_id=${orgId}`
  );

  return response.data;
};

export const sendTiktokVideoQuery = async (query, orgId) => {
  const response = await api.get(
    `https://ai-server.stickball.biz/search_tiktok?query=${query}&org_id=${orgId}`
  );
  return response.data;
};

const constructPrompt = ({
  data,
  pageName,
  gradeNumber,
  askAnythingPrompt,
  moduleName,
  lessonName,
  skillName,
  subSkillName,
  subSkillDescription,
  selectedLanguage,
}) => {
  if (pageName == "Module")
    return `${askAnythingPrompt}
            Currently you are on ${pageName} screen.
            Now answer the questions from the content provided.
            Answer should be:
            - IMPORTANT: You must ALWAYS respond in ${selectedLanguage} language regardless of the input language
            - Even if the question is asked in any other language, your response must strictly be in ${selectedLanguage} only
            - Your answer should always be according to the Levine Logic.
            - Always be careful about the answer content, content should be according to the GradeLexile® Range for the students of grade ${gradeNumber}.
            - Increase or decrease the content according to the GradeLexile® Range.
            - Answer should always be less than 200 words.
            If any question is out of the context provided to you or not related to financial literacy domain then you must have to say "Sorry, I don't have information related to that" in ${selectedLanguage}.`;
  if (pageName == "Lesson")
    return `${askAnythingPrompt}
            Currently you are on ${pageName} screen.
            The user has chosen the module ${moduleName}
            The list of lesson topics with their respective skills in JSON is ${data}
            Now answer the questions from the content provided.
            Answer should be:
            - IMPORTANT: You must ALWAYS respond in ${selectedLanguage} language regardless of the input language
            - Even if the question is asked in any other language, your response must strictly be in ${selectedLanguage} only
            - Your answer should always be according to the Levine Logic.
            - Always be careful about the answer content, content should be according to the GradeLexile® Range for the students of grade ${gradeNumber}.
            - Increase or decrease the content according to the GradeLexile® Range.
            - Answer should always be less than 200 words.
            If any question is out of the context provided to you or not related to financial literacy domain then you must have to say "Sorry, I don't have information related to that" in ${selectedLanguage}.`;
  if (pageName == "SubSkills")
    return `${askAnythingPrompt}
            Currently you are on ${pageName} screen.
            The user has chosen the module ${moduleName}
            The user has chosen the lesson ${lessonName}
            The user has chosen the skill ${skillName}
            The list of sub skills topics with their description in JSON is ${data}
            Now answer the questions from the content provided.
            Answer should be:
            - IMPORTANT: You must ALWAYS respond in ${selectedLanguage} language regardless of the input language
            - Even if the question is asked in any other language, your response must strictly be in ${selectedLanguage} only
            - Your answer should always be according to the Levine Logic.
            - Always be careful about the answer content, content should be according to the GradeLexile® Range for the students of grade ${gradeNumber}.
            - Increase or decrease the content according to the GradeLexile® Range.
            - Answer should always be less than 200 words.
            If any question is out of the context provided to you or not related to financial literacy domain then you must have to say "Sorry, I don't have information related to that" in ${selectedLanguage}.`;
  if (pageName == "Skill Section")
    return `${askAnythingPrompt}
            Currently you are on ${pageName} screen.
            The user has chosen the module ${moduleName}
            The user has chosen the lesson ${lessonName}
            The user has chosen the skill ${skillName}
            The user has chosen the sub skill ${subSkillName} and it's description ${subSkillDescription}
            The content of skill section in JSON is ${data}
            Now answer the questions from the content provided.
            Answer should be:
            - IMPORTANT: You must ALWAYS respond in ${selectedLanguage} language regardless of the input language
            - Even if the question is asked in any other language, your response must strictly be in ${selectedLanguage} only
            - Your answer should always be according to the Levine Logic.
            - Always be careful about the answer content, content should be according to the GradeLexile® Range for the students of grade ${gradeNumber}.
            - Increase or decrease the content according to the GradeLexile® Range.
            - Answer should always be less than 200 words.
            If any question is out of the context provided to you or not related to financial literacy domain then you must have to say "Sorry, I don't have information related to that" in ${selectedLanguage}.`;
};
