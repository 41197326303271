import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generateManifest } from '../utils/manifestGenerator';

const DynamicManifest = () => {
    const userData = useSelector((state) => state?.user?.userData);
    const { user } = userData;

    useEffect(() => {
        if (user?.organizationId) {
            const manifest = generateManifest(user?.organizationId);
            console.log(manifest);
            const stringManifest = JSON.stringify(manifest);
            const blob = new Blob([stringManifest], { type: 'application/json' });
            const manifestURL = URL.createObjectURL(blob);

            // Update manifest link
            const manifestLink = document.querySelector('link[rel="manifest"]');
            if (manifestLink) {
                manifestLink.href = manifestURL;
            }

            //Update favicon
            const favicon = document.querySelector('link[rel="icon"]');
            if (favicon) {
                favicon.href = manifest.favicon.src;
            }

            // Update apple touch icon
            const appleTouchIcon = document.querySelector('link[rel="apple-touch-icon"]');
            if (appleTouchIcon) {
                appleTouchIcon.href = manifest.icon192.src;
            }

            // Update apple mobile web app title
            const appleWebAppTitle = document.querySelector('meta[name="apple-mobile-web-app-title"]');
            if (appleWebAppTitle) {
                appleWebAppTitle.content = manifest.short_name;
            }

            // Update apple touch startup image
            const appleStartupImage = document.querySelector('link[rel="apple-touch-startup-image"]');
            if (appleStartupImage) {
                appleStartupImage.href = manifest.icon512.src;
            }
        }
    }, [user, userData]);

    return null;
};

export default DynamicManifest; 