import React, { useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Stack,
    Divider,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';

// MUI Icons
import {
    Google as GoogleIcon,
    Apple as AppleIcon,
    Schedule as ScheduleIcon,
    ContentCopy as ContentCopyIcon,
    Check as CheckIcon
} from '@mui/icons-material';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import CustomCardWrapper from '../../../../common/contentType/CustomCardWrapper';

const ZoomCalendarButtons = ({ meetingDetails, name, description }) => {
    const [permissions, setPermissions] = useState({
        calendarAccess: true,
        reminders: true,
        shareDetails: true
    });
    const [copied, setCopied] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [selectedCalendar, setSelectedCalendar] = useState(null);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(meetingDetails?.joinUrl);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const addToGoogleCalendar = () => {
        const { topic, startTime, duration, joinUrl } = meetingDetails;

        const googleUrl = new URL('https://calendar.google.com/calendar/render');
        googleUrl.searchParams.append('action', 'TEMPLATE');
        googleUrl.searchParams.append('text', topic);
        googleUrl.searchParams.append('dates', formatDateForGoogle(startTime, duration));
        googleUrl.searchParams.append('details', `Join Zoom Meeting: ${joinUrl}`);
        googleUrl.searchParams.append('location', joinUrl);

        window.open(googleUrl.toString(), '_blank');
    };

    const addToAppleCalendar = () => {
        const icsContent = generateICSFile(meetingDetails);
        const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'zoom-meeting.ics';
        link.click();
    };

    const addToMicrosoftCalendar = () => {
        const { topic, startTime, duration, joinUrl } = meetingDetails;
        const start = new Date(startTime).toISOString();
        const end = new Date(new Date(startTime).getTime() + duration * 60000).toISOString();

        const outlookUrl = new URL('https://outlook.live.com/calendar/0/deeplink/compose');
        outlookUrl.searchParams.append('subject', topic);
        outlookUrl.searchParams.append('startdt', start);
        outlookUrl.searchParams.append('enddt', end);
        outlookUrl.searchParams.append('body', `Join Zoom Meeting: ${joinUrl}`);
        outlookUrl.searchParams.append('location', joinUrl);

        window.open(outlookUrl.toString(), '_blank');
    };

    const handleCalendarAdd = (calendarType) => {
        setSelectedCalendar(calendarType);
        setShowConfirmDialog(true);
    };

    const handleConfirmAdd = () => {
        switch (selectedCalendar) {
            case 'google':
                addToGoogleCalendar();
                break;
            case 'apple':
                addToAppleCalendar();
                break;
            case 'microsoft':
                addToMicrosoftCalendar();
                break;
            default:
                break;
        }
        setShowConfirmDialog(false);
    };

    return (
        <CustomCardWrapper>
            <Box sx={{ maxWidth: "100%", mx: 'auto', mt: 4, px: 2 }}>
                <Box sx={{ maxWidth: { md: 600, xs: "100%" }, mx: 'auto', mt: 4 }}>
                    <Typography variant="h3" textAlign="left">
                        {name}
                    </Typography>
                    <Typography variant="body1" textAlign="justify">
                        {description}
                    </Typography>
                </Box>
                <Card sx={{ maxWidth: { md: 600, xs: "100%" }, mx: 'auto', mt: 4, px: 2, borderRadius: 5 }} elevation={3}>
                    <CardContent>
                        <Box sx={{ textAlign: 'center', mb: 3 }}>
                            <Typography variant="h5" component="h2" gutterBottom>
                                {meetingDetails.topic}
                            </Typography>

                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                justifyContent="center"
                                sx={{ color: 'text.secondary' }}
                            >
                                <ScheduleIcon fontSize="small" />
                                <Typography variant="body1">
                                    {new Date(meetingDetails.startTime).toLocaleString()}
                                    <Typography
                                        component="span"
                                        sx={{ ml: 1, color: 'text.secondary' }}
                                    >
                                        ({meetingDetails.duration} minutes)
                                    </Typography>
                                </Typography>
                            </Stack>

                            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant="body2" sx={{ mr: 1 }}>
                                    Meeting Link:
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        maxWidth: '200px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {meetingDetails.joinUrl}
                                </Typography>
                                <IconButton
                                    size="small"
                                    onClick={handleCopyLink}
                                    sx={{ ml: 1 }}
                                >
                                    {copied ? <CheckIcon color="success" /> : <ContentCopyIcon />}
                                </IconButton>
                            </Box>
                        </Box>

                        <Divider sx={{ my: 3 }} />

                        <FormGroup sx={{ mb: 3 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={permissions.calendarAccess}
                                        onChange={(e) => setPermissions({ ...permissions, calendarAccess: e.target.checked })}
                                    />
                                }
                                label="Allow calendar app to access meeting details"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={permissions.reminders}
                                        onChange={(e) => setPermissions({ ...permissions, reminders: e.target.checked })}
                                    />
                                }
                                label="Send me meeting reminders"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={permissions.shareDetails}
                                        onChange={(e) => setPermissions({ ...permissions, shareDetails: e.target.checked })}
                                    />
                                }
                                label="Share meeting details with calendar"
                            />
                        </FormGroup>

                        <Stack spacing={2}>
                            <Button
                                variant="contained"
                                fullWidth
                                startIcon={<GoogleIcon />}
                                onClick={() => handleCalendarAdd('google')}
                                sx={{
                                    bgcolor: '#4285F4',
                                    '&:hover': { bgcolor: '#3367D6' }
                                }}
                            >
                                Add to Google Calendar
                            </Button>

                            <Button
                                variant="contained"
                                fullWidth
                                startIcon={<AppleIcon />}
                                onClick={() => handleCalendarAdd('apple')}
                                sx={{
                                    bgcolor: '#000000',
                                    '&:hover': { bgcolor: '#333333' }
                                }}
                            >
                                Add to Apple Calendar
                            </Button>

                            <Button
                                variant="contained"
                                fullWidth
                                startIcon={<MicrosoftIcon />}
                                onClick={() => handleCalendarAdd('microsoft')}
                                sx={{
                                    bgcolor: '#00A4EF',
                                    '&:hover': { bgcolor: '#0078D4' }
                                }}
                            >
                                Add to Microsoft Calendar
                            </Button>
                        </Stack>
                    </CardContent>
                </Card>

                <Dialog
                    open={showConfirmDialog}
                    onClose={() => setShowConfirmDialog(false)}
                >
                    <DialogTitle>Add to Calendar</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Are you sure you want to add this meeting to your calendar?
                            {!permissions.calendarAccess && " (Calendar access is disabled)"}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowConfirmDialog(false)}>Cancel</Button>
                        <Button
                            onClick={handleConfirmAdd}
                            variant="contained"
                            disabled={!permissions.calendarAccess}
                        >
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>

        </CustomCardWrapper>
    );
};

// Helper function to format date for Google Calendar
const formatDateForGoogle = (startTime, duration) => {
    const start = new Date(startTime);
    const end = new Date(start.getTime() + duration * 60000);

    return `${start.toISOString().replace(/-|:|\.\d+/g, '')}/${end.toISOString().replace(/-|:|\.\d+/g, '')
        }`;
};

// Helper function to generate ICS file content
const generateICSFile = (meetingDetails) => {
    const start = new Date(meetingDetails.startTime);
    const end = new Date(start.getTime() + meetingDetails.duration * 60000);

    return `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
DTSTART:${start.toISOString().replace(/[-:]/g, '').split('.')[0]}Z
DTEND:${end.toISOString().replace(/[-:]/g, '').split('.')[0]}Z
SUMMARY:${meetingDetails.topic}
DESCRIPTION:Join Zoom Meeting: ${meetingDetails.joinUrl}
LOCATION:${meetingDetails.joinUrl}
END:VEVENT
END:VCALENDAR`;
};

export default ZoomCalendarButtons;
