import {
  Box,
  InputAdornment,
  Modal,
  TextField,
  Button,
  IconButton,
  Typography,
  Grid,
  useMediaQuery,
  DialogContentText,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  Link,
  Slide,
  Menu,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";
import "./index.css";
import {
  createThreadId,
  sendMessageToGpt,
  sendMessageToModuleGpt,
  sendTiktokVideoQuery,
  sendYoutubeVideoQuery,
} from "../_api/chat-bot";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import toast from "react-hot-toast";
import Tutorial from "./page-tutorials";
import { ChatIconCustom } from "./IconsSvg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Markdown from "react-markdown";
import { sendGenricEmail } from "../_api/chat-bot";
import moment from "moment/moment";
import { UAParser } from "ua-parser-js";
import CustomVideoPlayer, {
  TiktokVideoPlayer,
} from "./AskAnything/CustomVideoPlayer";
import { VideoDisplayModal } from "./AskAnything/VideoDisplayModal";
import TextIcon from "@mui/icons-material/TextFields";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { TiktokIcon } from "./AskAnything/tiktokIcon";
import PodcastsIcon from '@mui/icons-material/Podcasts';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import CloseIcon from '@mui/icons-material/Close';
import NewbieIcon from "./IconsSvg/Newbie";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CrownIcon from "./IconsSvg/Crown";
import GTranslateRoundedIcon from '@mui/icons-material/GTranslateRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckIcon from '@mui/icons-material/Check';


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const chatbotTypes = [
  {
    title: "Text",
    icon: <TextIcon />,
    value: "text",
    disabled: false
  },
  {
    title: "YouTube",
    icon: <YouTubeIcon />,
    value: "youtube",
    disabled: false
  },
  // {
  //   title: "TikTok",
  //   icon: <TiktokIcon size="1.5rem" />,
  //   value: "tiktok",
  //   disabled: false
  // },
  {
    title: "The Stick",
    icon: <PodcastsIcon />,
    value: "stick",
    disabled: true
  }
]

const workshopQuestions = [
  "What is the purpose of workshops?",
  "How are workshops structured?",
  "What is covered in the 'Introduction to Assessment'?",
  "How do credit podcasts help learners?",
  "What insights are provided in 'Credit Insights'?",
  "How do practice sessions improve skills?",
  "What happens during live Q&A sessions?",
  "Are workshops self-paced or live?",
  "How are topics selected in workshops?",
  "What support is available for workshop participants?"
];

const languages = [
  { code: 'en', name: 'English' },
  { code: 'es', name: 'Español' },
  { code: 'fr', name: 'Français' },
  { code: 'de', name: 'Deutsch' },
  { code: 'it', name: 'Italiano' },
  { code: 'pt', name: 'Português' },
  { code: 'ru', name: 'Русский' },
  { code: 'zh', name: '中文' },
  { code: 'ja', name: '日本語' },
  { code: 'ko', name: '한국어' },
  { code: 'ar', name: 'العربية' },
  { code: 'hi', name: 'हिन्दी' },
  { code: 'bn', name: 'বাংলা' },
  { code: 'tr', name: 'Türkçe' },
  { code: 'vi', name: 'Tiếng Việt' },
  { code: 'th', name: 'ไทย' },
  { code: 'nl', name: 'Nederlands' },
  { code: 'pl', name: 'Polski' },
  { code: 'el', name: 'Ελληνικά' },
  { code: 'he', name: 'עברית' }
];

const ChatBot = ({ data, pageName }) => {
  const [chatBotModal, setChatBotModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const unParsedData = localStorage.getItem("ModuleChatbot");
  const isModuleChatbot =
    unParsedData !== "false" ? JSON.parse(unParsedData) : "false";
  return (
    <div style={{ position: "relative" }}>
      <ChatBotBody
        moduleId={isModuleChatbot?.id}
        isModuleChatbot={false}
        setChatBotModal={setChatBotModal}
        data={data}
        pageName={pageName}
        chatBotModal={chatBotModal}
      />
      {pageName == "Skill Section" ? (
        <IconButton
          variant="rounded"
          onClick={() => {
            setChatBotModal(true);
          }}
        >
          <ChatIconCustom
            width={isMobile ? "20px" : "30px"}
            height={isMobile ? "20px" : "30px"}
            color={theme.palette.common.white}
          />
        </IconButton>
      ) : (
        <div
          className={"chat-btn-2"}
          style={{
            boxShadow: "0px 0px 20px 10px rgba(255,255,255, 0.2)",
          }}
          onClick={() => {
            setChatBotModal(true);
          }}
        >
          <ChatIconCustom color={theme.palette.primary.main} />

          {/* <img width={isMobile ? "30px" : "40px"} src={"../../assets/img/bot/bot-icon.svg"} alt="" /> */}
        </div>
      )}
    </div>
  );
};
export const ChatBotBody = ({
  setChatBotModal,
  chatBotModal,
  data,
  pageName,
  isModuleChatbot,
  moduleId,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t, i18n } = useTranslation();
  const choosenLanguage = i18n.language == "en" ? "English" : "Spanish";
  const messagesEndRef = useRef();
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [gradeNumber, setGradeNumber] = useState(10);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const [openFlagged, setOpenFlagged] = useState(false);
  const [flaggedMessage, setFlaggedMessage] = useState("");
  const [responseType, setResponseType] = useState("text");
  const isContentViolated =
    sessionStorage.getItem("contentViolation") === "Yes";
  const askAnythingPrompt =
    useSelector((state) => state.user.userData?.askAnythingPrompt) || "";
  let user = useSelector((state) => state.user.userData?.user);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const mediaRecorderRef = useRef(null);
  const silenceTimeoutRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [selectedLanguage, setSelectedLanguage] = useState("English");

  const moduleName = useSelector((state) => state.user?.moduleName);
  const lessonName = useSelector((state) => state.user?.lessonName);
  const skillName = useSelector((state) => state.user?.skillName);
  const subSkillName = useSelector((state) => state.user?.subSkillName);
  const subSkillDescription = useSelector(
    (state) => state.user?.subSkillDescription
  );

  useEffect(() => {
    setGradeNumber(localStorage.getItem("gradeNumber") || 10);
  }, []);

  // Old Implementation

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleToggle = (event, value) => {
    if (value !== "text") {
      setInputText("Provide a video on ");
    } else {
      setInputText("");
    }
    setResponseType(value);
    setMessages([]);
  };

  const handlesendMessage = async () => {
    if (!inputText.trim()) return;

    const newMessages = [...messages, { sender: "user", message: inputText }];
    let mesg = inputText;
    setMessages(newMessages);
    setInputText(responseType == "text" ? "" : "Provide a video on ");

    try {
      setIsLoading(true);
      let botReply;
      if (isModuleChatbot) {
        botReply = await sendMessageToModuleGpt(
          { prompt: mesg },
          moduleId
        );
      } else if (responseType == "youtube") {
        botReply = await sendYoutubeVideoQuery(mesg, user?.organizationId);
        if (typeof botReply !== "string") {
          botReply.type = "youtube";
        }
      } else if (responseType == "tiktok") {
        botReply = await sendTiktokVideoQuery(mesg, user?.organizationId);
        if (typeof botReply !== "string") {
          botReply.type = "tiktok";
        }
      } else {
        let payload = {
          newMessages,
          data,
          pageName,
          gradeNumber,
          askAnythingPrompt,
          threadId,
          moduleName,
          lessonName,
          skillName,
          subSkillName,
          subSkillDescription,
          selectedLanguage,
        };
        botReply = await sendMessageToGpt(payload);
      }
      setIsLoading(false);
      if (botReply?.isFlagged) {
        setOpenFlagged(true);
        setFlaggedMessage(mesg);
        sessionStorage.setItem("contentViolation", "Yes");
        setMessages([
          ...newMessages,
          { sender: "assistant", message: botReply?.message },
        ]);
        scrollToBottom();
        return null;
      }
      setMessages([...newMessages, { sender: "assistant", message: botReply }]);
      scrollToBottom();
    } catch (error) {
      setIsLoading(false);
      console.error("Error sending message:", error);
      // setChatBotModal(false);
      // Swal.fire({
      //   icon: "error",
      //   title: "Oops...",
      //   text: "Something went wrong!",
      // });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (chatBotModal && user?.organizationId == 14) {
      // for bch only
      createThreadId().then((res) => {
        setThreadId(res);
      });
    }
  }, [chatBotModal]);

  const handleSpeechToText = async () => {
    try {
      if (!isRecording) {
        // Request permission
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setHasPermission(true);
        setIsRecording(true);

        // Create speech recognition
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        const recognition = new SpeechRecognition();
        recognition.continuous = true;
        recognition.interimResults = true;

        recognition.onresult = (event) => {
          // Clear existing timeout
          if (silenceTimeoutRef.current) {
            clearTimeout(silenceTimeoutRef.current);
          }

          const transcript = Array.from(event.results)
            .map(result => result[0])
            .map(result => result.transcript)
            .join('');

          setInputText(transcript);

          // Set new timeout for 10 seconds of silence
          silenceTimeoutRef.current = setTimeout(() => {
            recognition.stop();
            setIsRecording(false);
          }, 12000);
        };

        recognition.onerror = (event) => {
          console.error('Speech recognition error:', event.error);
          setIsRecording(false);
          toast.error('Speech recognition failed. Please try again.');
        };

        recognition.onend = () => {
          if (silenceTimeoutRef.current) {
            clearTimeout(silenceTimeoutRef.current);
          }
          setIsRecording(false);
        };

        mediaRecorderRef.current = recognition;
        recognition.start();
      } else {
        // Stop recording
        if (silenceTimeoutRef.current) {
          clearTimeout(silenceTimeoutRef.current);
        }
        mediaRecorderRef.current?.stop();
        setIsRecording(false);
      }
    } catch (error) {
      console.error('Error accessing microphone:', error);
      toast.error('Unable to access microphone. Please check permissions.');
      setIsRecording(false);
    }
  };

  useEffect(() => {
    return () => {
      if (silenceTimeoutRef.current) {
        clearTimeout(silenceTimeoutRef.current);
      }
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
      }
    };
  }, []);

  return (
    <>
      <FlaggedDialog
        open={openFlagged}
        setOpen={setOpenFlagged}
        inputMessage={flaggedMessage}
      />
      <Dialog
        open={chatBotModal}
        onClose={() => setChatBotModal(false)}
        TransitionComponent={Transition}
        fullScreen={isFullScreen}
        PaperProps={{
          sx: {
            position: "fixed",
            margin: 0,
            bottom: 0,
            right: 0,
            width: { md: isFullScreen ? "100%" : "60vw", xs: "100%" },
            maxWidth: "100%",
            borderRadius: "30px 30px 0px 0px",
            overflowY: "visible",
          }
        }}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)'
          }
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "30px 30px 0px 0px",
            overflowY: "auto",
            padding: { md: "24px", sm: "16px", xs: "10px" },
            boxShadow: "0px 4px 115px rgba(0, 0, 0, 0.2)",
          }}
        >
          {/* header */}
          <Box
            sx={{ display: "flex", gap: { md: 2, xs: 1 }, justifyContent: "space-between", alignItems: "center" }}
          >
            {
              !(responseType == "youtube" || responseType == "tiktok") ? (
                <Button
                  aria-controls={openMenu ? 'language-menu' : undefined}
                  aria-haspopup="true"
                  variant="contained"
                  startIcon={<GTranslateRoundedIcon />}
                  endIcon={<ArrowDropDownRoundedIcon />}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                />
              )
                :
                <span></span>
            }

            <Menu
              id="language-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                'aria-labelledby': 'language-menu',
              }}
              PaperProps={{
                style: {
                  maxHeight: '300px',
                  width: '200px',
                }
              }}
            >
              {languages.map((language) => (
                <MenuItem
                  key={language.code}
                  onClick={() => {
                    setSelectedLanguage(language.name);
                    // i18n.changeLanguage(language.code);
                    setAnchorEl(null);
                  }}
                  selected={selectedLanguage === language.name}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  {language.name}
                  {selectedLanguage === language.name && (
                    <CheckIcon color="primary" fontSize="small" />
                  )}
                </MenuItem>
              ))}
            </Menu>
            <IconButton sx={{ height: "fit-content" }} color="error" aria-label="" onClick={() => setChatBotModal(false)}>
              <CloseRoundedIcon sx={{ fontSize: "30px" }} />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1, width: "100%" }}>
            <Box sx={{ display: "flex", gap: { md: 2, sm: 1, xs: 0.5 }, justifyContent: "flex-end" }}>
              <Button
                sx={{ bgcolor: gradeNumber == 3 && theme.palette.primary.light }}
                startIcon={
                  <NewbieIcon color={gradeNumber == 3 ? theme.palette.primary.main : theme.palette.secondary.main} />
                }
                onClick={() => {
                  toast.success(`${t("gradeUpdatedMessage")} Newbie`);
                  localStorage.setItem("gradeNumber", 3);
                  setGradeNumber(3);
                }}
                variant="outlined" color={gradeNumber == 3 ? "primary" : "secondary"}
              >Newbie</Button>
              <Button
                sx={{ bgcolor: gradeNumber == 6 && theme.palette.primary.light }}
                startIcon={
                  <SearchRoundedIcon />
                }
                onClick={() => {
                  toast.success(`${t("gradeUpdatedMessage")} Explorer`);
                  localStorage.setItem("gradeNumber", 6);
                  setGradeNumber(6);
                }}
                variant="outlined" color={gradeNumber == 6 ? "primary" : "secondary"}
              >Explorer</Button>
              <Button
                sx={{ bgcolor: gradeNumber == 10 && theme.palette.primary.light }}
                startIcon={
                  <CrownIcon color={gradeNumber == 10 ? theme.palette.primary.main : theme.palette.secondary.main} />
                }
                onClick={() => {
                  toast.success(`${t("gradeUpdatedMessage")} Pro`);
                  localStorage.setItem("gradeNumber", 10);
                  setGradeNumber(10);
                }}
                variant="outlined" color={gradeNumber == 10 ? "primary" : "secondary"}
              >Pro</Button>
            </Box>

          </Box>
          {/* body */}
          <Box sx={{ position: "relative", }}>
            <Box
              sx={{
                justifyContent: !messages.length && "center",
                height: "calc(100vh - 270px)",
                ...chatbotBody
              }}
            >
              {!messages.length && (
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start", justifyContent: "center",
                  width: { md: "70%", xs: "90%" },
                  mx: "auto",
                  '@media (max-height: 600px)': {
                    mb: "80px"
                  },
                  height: "100%",
                }}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: { md: 1, xs: 0.5 },
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "fit-content",
                    }}
                  >
                    <ChatIconCustom
                      width={isMobile ? "30px" : "50px"}
                      height={isMobile ? "30px" : "50px"}
                      color={theme.palette.primary.main}
                    />
                    <Typography variant={"h6"} sx={{ fontSize: { md: "20px", xs: "12px" } }} fontWeight={600} color="primary">{t("askAnything")}</Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{
                      fontWeight: { sm: 500, xs: 400 },
                    }}
                  >
                    {t("emptyChatText1")}
                  </Typography>
                </Box>
              )}
              {messages?.map((val) => (
                <>
                  <EachMessage
                    isLoading={isLoading}
                    val={val}
                    responseType={responseType}
                  />
                </>
              ))}
              {isLoading && (
                <EachMessage
                  val={{ sender: "assistant", message: t("typing") + "..." }}
                />
              )}
              <div ref={messagesEndRef} />
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                pb: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: { md: 1, xs: 0.5 },
                mx: "auto",
                overflow: "hidden",
                backgroundColor: "white",
              }}>
              {/* {
                !messages.length && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: "center",
                      flexWrap: 'wrap',
                      gap: '5px',
                      pb: "5px",
                      maxHeight: '30px',
                      overflowY: 'auto',
                      '&::-webkit-scrollbar': {
                        height: '0px',
                        width: '0px',
                      },
                      '&::-webkit-scrollbar-track': {
                        background: 'none',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: '#fff',
                      },
                    }
                    }
                  >
                    {
                      workshopQuestions.map((question, index) => (
                        <Button
                          key={index}
                          variant="outlined"
                          color="primary"
                          size="small"
                          sx={{
                            borderRadius: '8px',
                            padding: '2px 8px',
                            minWidth: 'fit-content',
                            whiteSpace: 'nowrap',
                          }}
                          onClick={() => {
                            setInputText(question);
                          }}
                        >
                          <Typography variant="body1" fontWeight={400} color="initial">{question}</Typography>
                        </Button>
                      ))
                    }
                  </Box>
                )
              } */}
              <Box sx={{ display: "flex", gap: { md: 2, xs: 1 }, width: "100%" }}>
                {
                  chatbotTypes.map((val, index) => (
                    <Button
                      fullWidth
                      disabled={val.disabled}
                      variant="outlined"
                      color={responseType == val.value ? "primary" : "secondary"}
                      onClick={(e) => handleToggle(e, val.value)}
                      sx={{
                        ...buttonStyle,
                        borderColor: responseType == val.value ? theme.palette.primary.main : theme.palette.secondary.light,
                        color: responseType == val.value ? theme.palette.primary.main : theme.palette.secondary.main,
                        backgroundColor: responseType == val.value ? theme.palette.primary.light : theme.palette.secondary.light
                      }}
                    >
                      {val.icon}  {val.title}
                    </Button>
                  ))
                }
              </Box>
            </Box>
          </Box>




          {isContentViolated && (
            <Typography textAlign={"center"} variant="body1" color="error">
              Your Ask Anything facility has been disabled. Please contact at{" "}
              <Link href="mailto:developers@stickball.biz">
                developers@stickball.biz
              </Link>
            </Typography>
          )}
          <Box
            className="chatbot-header-container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Tutorial page="askAnything" />
            <TextField
              disabled={isContentViolated}
              size="small"
              placeholder={t("enterQuestion")}
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handlesendMessage();
                }
              }}
              id="outlined-basic"
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              variant="outlined"
              InputProps={{
                sx: { borderRadius: 2, p: 1 },
                endAdornment: (
                  <InputAdornment position="end">
                    <Box sx={{ display: "flex", alignItems: "center", gap: { md: 0.5, xs: 0.2 } }}>
                      {
                        inputText && (
                          <IconButton
                            color="primary"
                            onClick={() => setInputText("")}
                          >
                            <CloseIcon />
                          </IconButton>
                        )
                      }
                      <IconButton
                        color="primary"
                        onClick={handleSpeechToText}
                        sx={{
                          ...(isRecording && {
                            backgroundColor: 'rgba(234, 67, 53, 0.1)',
                            animation: 'pulse 1.5s ease-in-out infinite',
                            '@keyframes pulse': {
                              '0%': {
                                transform: 'scale(1)',
                                boxShadow: '0 0 0 0 rgba(234, 67, 53, 0.4)'
                              },
                              '70%': {
                                transform: 'scale(1.1)',
                                boxShadow: '0 0 0 10px rgba(234, 67, 53, 0)'
                              },
                              '100%': {
                                transform: 'scale(1)',
                                boxShadow: '0 0 0 0 rgba(234, 67, 53, 0)'
                              }
                            }
                          })
                        }}
                      >
                        {isRecording ? (
                          <StopIcon sx={{ color: '#EA4335' }} />
                        ) : (
                          <MicIcon />
                        )}
                      </IconButton>
                      <SendIcon
                        color={isContentViolated ? "disabled" : "primary"}
                        sx={{
                          cursor: isContentViolated ? "not-allowed" : "pointer",
                        }}
                        onClick={handlesendMessage}
                      />
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Dialog >
    </>
  );
};
const EachMessage = ({ val, responseType }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const botBorderRadius = "0px 8px 8px 8px";
  const userBorderRadius = "8px 0px 8px 8px";
  const isBot = val.sender == "assistant";

  const handleModalOpen = () => {
    if ((val?.message?.type == "youtube" && val?.message?.url) || (val?.message?.type == "tiktok" && val?.message?.url)) {
      setOpenVideoModal(true);
    } else {
      return;
    }
  };

  const handleModalClose = () => {
    setOpenVideoModal(false);
  };

  const getYouTubeVideoId = (url) => {
    const regex = /[?&]v=([a-zA-Z0-9_-]+)/;
    const match = url?.match(regex);
    return match ? match[1] : null;
  };

  return (
    <>
      <div className={isBot ? "each-message" : "each-message-user"}>
        <div
          className="chat-bot-profile-pic"
          style={{ padding: 0, background: !isBot && "white" }}
        >
          {isBot && (
            <ChatIconCustom
              width={isMobile ? "30px" : "50px"}
              height={isMobile ? "30px" : "50px"}
              color={theme.palette.primary.main}
            />
          )}
          {!isBot && (
            <AccountCircleIcon
              sx={{
                width: isMobile ? "30px" : "50px",
                height: isMobile ? "30px" : "50px",
              }}
              color="secondary"
            />
          )}
        </div>
        <Box
          sx={{
            display: "flex",
            padding: { md: "10px 15px", xs: "7px 15px" },
            maxHeight: "fit-content",
            borderRadius: isBot ? botBorderRadius : userBorderRadius,
            backgroundColor: (val?.message?.url && responseType == "tiktok") ? "transparent" : isBot
              ? theme.palette.primary.main
              : theme.palette.secondary.main,
            maxWidth: {
              sm:
                val?.message?.url
                  ? "fit-content"
                  : "50%",
              xs:
                val?.message?.url
                  ? "fit-content"
                  : "70%",
              md: val?.message?.url ? "fit-content" : "50%",
            },
            width: "fit-content",
            position: "relative",
            top: { md: "12px", xs: "6px" },
            color: "white",
            alignItems: "center",
            cursor:
              val?.message?.url
                ? "pointer"
                : "default",
          }}
          onClick={handleModalOpen}
        >
          <p
            className="chat-bot-message-text"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Markdown
              components={{
                h1: (props) => <h1 style={{ color: "inherit" }} {...props} />,
                h2: (props) => <h2 style={{ color: "inherit" }} {...props} />,
                h3: (props) => <h3 style={{ color: "inherit" }} {...props} />,
                h4: (props) => <h4 style={{ color: "inherit" }} {...props} />,
                h5: (props) => <h5 style={{ color: "inherit" }} {...props} />,
                h6: (props) => <h6 style={{ color: "inherit" }} {...props} />,
                p: (props) => <p style={{ margin: "0px", color: "inherit" }} {...props} />,
              }}
            >
              {val?.message?.type == "tiktok" && val?.message?.url
                ? ""
                : val?.message?.type == "tiktok" ?
                  val?.message?.title
                  : (val?.message?.type == "youtube" && val?.message?.title) ||
                  val?.message}
            </Markdown>
            {isBot && val?.message?.url && (
              <>
                {val?.message?.type === "youtube" && (
                  <CustomVideoPlayer
                    linkId={getYouTubeVideoId(val?.message?.url)}
                  />
                )}

                {val?.message?.type === "tiktok" && (
                  <TiktokVideoPlayer videoUrl={val?.message?.url} />
                )}
              </>
            )}
          </p>
        </Box>
      </div>
      <VideoDisplayModal
        link={
          responseType == "youtube"
            ? getYouTubeVideoId(val?.message?.url)
            : val?.message?.url
        }
        open={openVideoModal}
        handleClose={handleModalClose}
        responseType={responseType}
      />
    </>
  );
};

export default ChatBot;

function getSystemAndBrowserInfo() {
  const parser = new UAParser();
  const result = parser.getResult();

  const deviceType = `${result.device.vendor} ${result.device.model || "Desktop"
    }`;
  const os = `${result.os.name} ${result.os.version || ""}`;
  const browser = `${result.browser.name} ${result.browser.version || ""}`;

  return {
    deviceType,
    os,
    browser,
  };
}

const FlaggedDialog = ({ open, setOpen, inputMessage }) => {
  const user = useSelector((state) => state?.user?.userData?.user);
  const systemInfo = getSystemAndBrowserInfo();

  useEffect(() => {
    const fetchUserInfo = async () => {
      let email = localStorage.getItem("userEmail");
      let userDetials = JSON.parse(localStorage.getItem("userDetails"));
      // const systemInfo = await navigator.userAgent;
      // const browserInfo = await navigator.appVersion;
      // const userLocation = await new Promise((resolve) => {
      //     navigator.geolocation.getCurrentPosition((position) => {
      //         resolve(position.coords);
      //     });
      // });

      let tempPayload = {
        name: userDetials?.name,
        email: email,
        coach: userDetials?.coach,
        mentor: userDetials?.mentor,
        system_info: systemInfo,
        // latitude: userLocation.latitude,
        // longitude: userLocation.longitude,
        current_time: new Date().toISOString(),
        organizationId: user?.organizationId,
        organizationName: "Boston Children Hospital",
      };
      const message = `
Hello Stickball,

We have detected a content moderation violation while processing a request through OpenAI's API. The details of this incident are as follows:


Input Content Triggering Violation

    The following input text was flagged by OpenAI's content moderation for a potential policy violation:

        Text: ${inputMessage}

        Date and Time of Violation: ${moment(tempPayload.current_time).format(
        "MMM Do hh:mm:A"
      )}

User Details

    • Full Name: ${tempPayload.name || "N/A"}
    • Email Address: ${tempPayload.email || "N/A"}
    • Success Coach: ${tempPayload.coach || "N/A"}
    • Mentor: ${tempPayload.mentor || "N/A"}

Organization Details

    • Organization ID: ${tempPayload.organizationId || "N/A"}
    • Organization Name: ${tempPayload.organizationName}

Application Details

    • Application Name: Client App
    • URL: ${window.location.href}
    • Environment: Production
    • Feature: Ask Anything Bot
    • Static Site: Yes


System and Browser Information

    • Device: ${tempPayload.system_info.deviceType || ""}
    • Operating System: ${tempPayload.system_info.os || ""}
    • Browser: ${tempPayload.system_info.browser || ""}
    • Timestamp: ${moment(tempPayload.current_time).format("hh:mm:A")}

Location Information

    • Latitude: ${tempPayload?.latitude || "N/A"}
    • Longitude: ${tempPayload?.longitude || "N/A"}

Please review this incident promptly to ensure appropriate action is taken.

Thank you.

Regards,
Development Team
`;
      let mailInfoToSend = {
        to: ["developers@stickball.biz"],
        cc: [],
        subject: `Urgent OpenAI Violation Alert - ${tempPayload.organizationName} - Production Environment, Static App`,
        message: message,
      };
      if (open && inputMessage) {
        sendGenricEmail(mailInfoToSend);
      }
    };

    fetchUserInfo();
  }, [open, inputMessage]);
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
      PaperProps={{
        sx: {
          borderRadius: "32px",
          pt: "30px",
          minHeight: "200px",
        },
      }}
    >
      <DialogTitle textAlign="center" color="error">
        Content Violation Alert
      </DialogTitle>
      <DialogContent>
        <DialogContentText textAlign="center" id="alert-dialog-description">
          The message has content violations.
          <br />
          Your Ask Anything facility has been disabled. Please contact at{" "}
          <Link href="mailto:developers@stickball.biz">
            developers@stickball.biz
          </Link>
        </DialogContentText>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}></Box>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ mr: 2, mb: 1 }}
          variant="contained"
          onClick={() => setOpen(false)}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const buttonStyle = {
  minWidth: { md: "40px", xs: "60px" },
  borderRadius: 2,
  padding: { md: 1, xs: 0.5 },
  fontSize: { md: "1rem", xs: "10px" },
  display: "flex",
  flexDirection: { md: "row", xs: "column" },
  gap: { md: 1, xs: 0.5 },
  overflow: "hidden",
  transition: "all 0.6s ease-in-out",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}

const chatbotBody = {
  overflowY: "auto",
  transition: "all 0.3s ease-in-out",
  pb: { md: "80px", xs: "80px" },
  WebkitOverflowScrolling: "touch", // Enable momentum scrolling for iOS
  "&::-webkit-scrollbar": {
    width: "5px", // Adjust the width of the scrollbar
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0, 0, 0, 0.3)", // Adjust the color of the scrollbar thumb
    borderRadius: "5px", // Adjust the border radius of the scrollbar thumb
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the color of the scrollbar thumb on hover
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent", // Set the background color of the scrollbar track to transparent
  },
}